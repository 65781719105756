<template>
  <div class="ui labeled icon left inline vertical sidebar menu" style>
    <a class="item" href="/">
      <i class="home icon"></i>
      {{titulo}}
    </a>
    <a
      v-for="menu in menus"
      :key="menu.id"
      class="item"
      v-bind:hrefˇ="menu.href"
      v-bind:class="{ active: menu.activa }"
      data-turbolinks="false"
      @click="acceso(menu.href)"
    >
      <i v-bind:class="menu.icon"></i>
      {{menu.opcion}}
    </a>
  </div>
</template>

<script>
const Api = require("../packs/api");

export default {
  props: ["activo", "titulo"],
  data: function() {
    return {
      message: "Hello Vue!",
      menus: [
        {
          id: 1,
          opcion: "Facturas",
          icon: "shopping cart icon",
          activa: this.menuactivo("Facturas"),
          href: "/facturas"
        },
        {
          id: 2,
          opcion: "Albaranes",
          icon: "dolly icon",
          activa: this.menuactivo("Albaranes"),
          href: "/albaranes"
        },
        {
          id: 3,
          opcion: "Presupuestos",
          icon: "clipboard list icon",
          activa: this.menuactivo("Presupuestos"),
          href: "/presupuestos"
        },
        {
          id: 4,
          opcion: "Clientes",
          icon: "users icon",
          activa: this.menuactivo("Clientes"),
          href: "/clientes"
        },
        {
          id: 5,
          opcion: "Entregas",
          icon: "money bill alternate outline icon",
          activa: this.menuactivo("Entregas"),
          href: "/entregas"
        },
        {
          id: 6,
          opcion: "Caja",
          icon: "euro sign icon",
          activa: this.menuactivo("Caja"),
          href: "/cajas"
        }
      ]
    };
  },
  methods: {
    acceso: function(p) {
      $(".ui.labeled.icon.sidebar").sidebar("toggle");
      location.href = p;
    },
    menuactivo: function(o) {
      return o == this.activo;
    }
  }
};
</script>

<style scoped>
.item.bottom {
  top: 100% !important;
  position: ‘stiky’ !important;
}

p {
  font-size: 2em;
  text-align: center;
}
</style>
