/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

import Vue from 'vue'
import Menupral from '../components/menu.vue'
//import App from '../app.vue'

Vue.component('menupral', Menupral)
//Vue.component('appvue', App)

document.addEventListener('DOMContentLoaded', () => {
    // const appvue = new Vue({
    //     el: '[data-behavior="vuepral"]',
    //     data: function () {
    //         return {
    //             bruto: 0,
    //             tipoivasel: 21,
    //             lineadatos: {},
    //             descuento: 0
    //         }
    //     }
    // });
    
    const menupral = new Vue({
        el: '[data-behavior="menuvue"]'
    });

    // const clientevue = new Vue({
    //     el: '[data-behaviour="clientes2"]',
    //     data: {
    //         mensaje: 'Lista de clientes',
    //         //clientes: []
    //     },
    //     beforeMount() {
    //         console.log('--- Mount() prueba---');
    //         //this.clientes = [{id:3, nombre:'Laura'}]
    //     },
    //     components: {
    //         Clientevue
    //     } 
    // })

    $(".botonmenu").on("click", function () {
        console.log('....-----.....');
        $('.ui.labeled.icon.sidebar')
            .sidebar('toggle');
    });

    // Tengo que poner la clase que afecte solamente al dropdown del menu
    // de la barra principal superior ya que si pongo de forma global ui.dropdown
    // no funcionan los dropdown del iva, por ejemplo
    $('.ui.dropdown.menupral').dropdown();
})